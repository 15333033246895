<template>
  <div>
    <HeaderPanel
      title="Role"
      :filter="filter"
      placeholder="Search Role Name"
      @search="getRoleList"
      :hasFilter="false"
      :hasDropdown="false"
      routerPath="/manageRole/detail/0"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <Table
        :filter="filter"
        :pageOptions="pageOptions"
        :fields="fields"
        :items="items"
        :isBusy="isBusy"
        :rows="rows"
        :showingTo="showingTo"
        @filterPage="filterPage"
        @getData="getRoleList"
      />
    </div>
  </div>
</template>
<script>
import HeaderPanel from "@/components/HeaderPanel";
import Table from "@/components/role/Table";

export default {
  components: {
    HeaderPanel,
    Table,
  },
  data() {
    return {
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: null,
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          key: "name",
          label: "Role Name",
        },
        {
          key: "createdTime",
          label: "Create Date",
          class: "text-center",
        },
        {
          key: "updatedTime",
          label: "Last Update",
          class: "text-center",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "actions",
          label: "Action"
        },
      ],
      items: [],
    };
  },
  watch: {
    RoleSelected(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.RoleSelectedAll = false;
      } else if (newValue.length === this.Role.length) {
        this.RoleSelectedAll = true;
      } else {
        this.RoleSelectedAll = false;
      }
    },
  },
  created() {
    this.getRoleList();
  },
  methods: {
    async getRoleList() {
      this.isBusy = true;
      const response = await this.$Axios.post(
        `${process.env.VUE_APP_API}/Role/getList`,
        this.filter
      );
      this.isBusy = false;
      this.items = response.data.detail.listRole;
      this.rows = response.data.detail.total;
    },
    filterPage(filter) {
      this.filter = filter;
      this.getRoleList();
    },
  },
};
</script>
