<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(createdTime)="{ item }">
            <div>{{ item.createdTime | moment($formatDateNew) }}</div>
            <div class="time-color">
              {{ item.createdTime | moment("HH:mm:ss") }}
            </div>
          </template>
          <template v-slot:cell(updatedTime)="{ item }">
            <div>{{ item.updatedTime | moment($formatDateNew) }}</div>
            <div class="time-color">
              {{ item.updatedTime | moment("HH:mm:ss") }}
            </div>
          </template>
          <template v-slot:cell(status)="{ item }">
            <span :class="item.status ? 'text-success' : 'text-error'">{{
              item.status ? "Active" : "Inactive"
            }}</span>
          </template>
          <template v-slot:cell(actions)="{ item }">
            <div class="d-flex justify-content-center">
              <router-link :to="'/manageRole/detail/' + item.id">
                <b-button variant="link" class="text-warning px-1 py-0">
                  <font-awesome-icon icon="pencil-alt" title="Edit" />
                </b-button>
              </router-link>
            </div>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import ModalLoading from "@/components/modal/ModalLoading";
import Pagination from "@/components/Pagination";
export default {
  components: {
    ModalLoading,
    Pagination,
  },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
  },
  methods: {
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
    },
    chkBirthday(birthday) {
        let birthDate = birthday ? birthday.search("00:00:00") : -1;
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
  //   ShowDeleteData(id) {
  //     this.confirmAlert().then((val) => {
  //       if (val.isConfirmed) {
  //         this.deleteData(id);
  //       }
  //     });
  //   },
  //   async deleteData(id) {
  //     this.$refs.modalLoading.show();
  //     const data = await this.$Axios.delete(
  //       `${process.env.VUE_APP_API}/Role/${id}`
  //     );
  //     if (data.result == 1) {
  //       this.$refs.modalLoading.hide();
  //       this.successAlert().then(() => {
  //         this.$emit("getData");
  //       });
  //     } else {
  //       this.$refs.modalLoading.hide();
  //       this.errorAlert();
  //     }
  //   },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}
</style>
